import {
    Autocomplete,
    Box,
    Button,
    Group,
    LoadingOverlay,
    Select,
    TextInput,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { ContextModalProps } from "@mantine/modals";
import {
    Calendar,
    CalendarQuery,
    SXPContext,
    dateToLocalString,
    sxpapi,
} from "@sxp-api-lib/index";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import TimeInput from "../../../../components/TimeInput";
import { postProcessCalendarPayload } from "../../../../helpers/CalendarHelper";
import { AppContext } from "../../../../AppContext";
import { sxpctx } from "../../../..";
import { AuthToken } from "@sxp-api-lib/api/sxpapi";

export type FormValuesType = {
    id: string;
    startTime: string;
    endTime: string;
    region: string;
    dates: Date[];
    suffix: string;
    extra: string;
};

const defaultFormValues = {
    id: "",
    startTime: "",
    endTime: "",
    dates: [],
    region: "",
    suffix: "",
    extra: "",
} as FormValuesType;

export default function WeeklyCalendarManagerEditModal(
    props: ContextModalProps<{
        id?: string;
        copyId?: string;
    }>
) {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [suffixes, setSuffixes] = useState([] as string[]);
    const form = useForm({ initialValues: defaultFormValues });

    const queryClient = useQueryClient();

    const { mutate } = useMutation({
        mutationFn: async (values: FormValuesType) => {
            setSaving(true);

            const payload = createPayload(values);

            if (payload.id) {
                return await sxpctx.getQuery(CalendarQuery).update(payload);
            } else {
                return await sxpctx.getQuery(CalendarQuery).create(payload);
            }
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["calendars"] });
            setSaving(false);
            props.context.closeModal(props.id);
        },
    });

    useEffect(() => {
        const promises = [
            sxpapi
                .get(
                    "/items/calendars?groupBy[]=suffix",
                    sxpctx.authToken as AuthToken
                )
                .then((suffixes) => {
                    const parsedSuffixes = suffixes.data.data
                        .map((e: any) => e.suffix)
                        .filter((e: any) => e);

                    setSuffixes(parsedSuffixes);
                }),
        ];

        if (props.innerProps.id) {
            promises.unshift(
                sxpctx
                    .getQuery(CalendarQuery)
                    .fetch({ id: props.innerProps.id })
                    .then((calendar) => {
                        form.setValues(createFormValues(calendar));
                    })
            );
        }

        if (props.innerProps.copyId) {
            promises.unshift(
                sxpctx
                    .getQuery(CalendarQuery)
                    .fetch({ id: props.innerProps.copyId })
                    .then((calendar) => {
                        const formValues = createFormValues(calendar);
                        form.setValues({ ...formValues, id: undefined });
                    })
            );
        }

        Promise.all(promises)
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }, [props.context.openModal]);

    return (
        <Box pos="relative">
            <LoadingOverlay visible={loading} overlayBlur={2} />
            <form onSubmit={form.onSubmit((values) => mutate(values))}>
                <TimeInput
                    hidden
                    required
                    label={t("calendarManager.startTime")}
                    {...form.getInputProps("startTime")}
                />
                <TimeInput
                    hidden
                    required
                    label={t("calendarManager.endTime")}
                    {...form.getInputProps("endTime")}
                />
                <DatePickerInput
                    label={t("calendarManager.dates")}
                    {...form.getInputProps("dates")}
                    type="multiple"
                    popoverProps={{
                        withinPortal: true,
                    }}
                />
                <Select
                    label={t("calendarManager.region")}
                    data={sxpctx.regions.a.map((e) => ({
                        value: e.id,
                        label: e.short_name,
                    }))}
                    {...form.getInputProps("region")}
                />
                <Autocomplete
                    label={t("calendarManager.session")}
                    data={suffixes}
                    {...form.getInputProps("suffix")}
                />
                <TextInput
                    label={t("calendarManager.extra")}
                    {...form.getInputProps("extra")}
                />
                <Group position="right" mt="md">
                    <Button
                        variant="subtle"
                        onClick={() => props.context.closeModal(props.id, true)}
                    >
                        {t("calendarManager.cancel")}
                    </Button>
                    <Button type="submit" loading={saving}>
                        {t("calendarManager.save")}
                    </Button>
                </Group>
            </form>
        </Box>
    );
}

function createFormValues(calendar: Calendar) {
    return {
        id: calendar.id,
        startTime: calendar.events[0].start.substring(11, 16),
        endTime: calendar.events[0].end.substring(11, 16),
        dates: calendar.events.map(
            (e: any, index: number) => new Date(calendar.events[index].start)
        ),
        region: calendar.region,
        suffix: calendar.suffix || "",
        extra: calendar.extra || "",
    } as FormValuesType;
}

function createPayload(obj: FormValuesType) {
    const events = obj.dates.map((e) => {
        const dateString = dateToLocalString(e).split("T")[0];

        return {
            start: dateString + "T" + obj.startTime + ":00",
            end: dateString + "T" + obj.endTime + ":00",
        };
    });

    const payload = {
        id: obj.id,
        events: events,
        region: obj.region,
        extra: obj.extra,
        format: "weekly",
        suffix: obj.suffix?.toUpperCase(),
    };

    return postProcessCalendarPayload(payload);
}
