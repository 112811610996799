import { arrayToObjectGeneric } from "../..";
import { SXPContext } from "../../SXPContext";
import { GenericQuery } from "./GenericQuery";

export type LocationFilter = {
    id?: string;
    languageCode?: string;
    status?: "public" | "hidden" | "archived";
};

export class Location {
    id: string;
    kind: "Location" = "Location";
    status: "public" | "hidden" | "archived";
    name: string;
    short_name: string;
    slug: string;
    link: string;
    virtual_location: boolean;
    label: string;
    establishment: string;
    address: string;
    details: string;
    wordpress_id: string;

    public constructor(data: any, context: SXPContext) {
        Object.assign(this, data);
    }
}

export class LocationQuery extends GenericQuery<Location, LocationFilter> {
    constructor(context: SXPContext) {
        super(context, Location, LocationQuery);
    }

    public static prepareInstanceFromGraphQL(data: any) {
        data.label = data.translations[0]?.label ?? "";
        data.establishment = data.translations[0]?.establishment ?? "";
        data.address = data.translations[0]?.address ?? "";
        data.details = data.translations[0]?.details ?? "";

        delete data.translations;

        return data;
    }

    public async fillContext(
        filter: LocationFilter,
        override: boolean = false
    ) {
        if (this.context.locations.a.length === 0 || override) {
            return this.fetchMany(filter).then((data) => {
                return (this.context.locations = arrayToObjectGeneric(
                    data,
                    "id"
                ));
            });
        }
    }

    public static getName() {
        return "LocationQuery";
    }

    public getGraphQLType() {
        return /* GraphQL */ `
            {
                id
                status
                slug
                name
                short_name

                link
                virtual_location
                translations(
                    filter: {
                        languages_code: { code: { _eq: $displayLanguage } }
                    }
                ) {
                    label
                    establishment
                    address
                    details
                }
                wordpress_id
            }
        `;
    }

    protected getQueryData(filter: LocationFilter) {
        const data: {
            filterArray: string[];
            filterParams: string[];
            variables: any;
        } = { filterArray: [], filterParams: [], variables: {} };

        if (filter.id) {
            data.filterArray.push(/* GraphQL */ `
                {
                    id: {
                        _eq: $id
                    }
                }
            `);
            data.filterParams.push("$id: String");
        }

        if (filter.languageCode) {
            data.filterArray.push(/* GraphQL */ `
                {
                    translations: {
                        languages_code: {
                            code: {
                                _eq: $languageCode
                            }
                        }
                    }
                }
            `);
            data.filterParams.push("$languageCode: String");
        }

        if (filter.status) {
            data.filterArray.push(/* GraphQL */ `
                {
                    status: {
                        _eq: $status
                    }
                }
            `);
            data.filterParams.push("$status: String");
        }

        data.filterParams.push("$displayLanguage: String");

        data.variables = {
            displayLanguage: this.context.language,
            id: filter.id,
            languageCode: filter.languageCode,
            status: filter.status,
        };

        return data;
    }

    protected getObjectTypeName(): string {
        return "Location";
    }

    protected getGraphQLName(): string {
        return "locations";
    }

    protected getRestEndPoint(): string {
        return "/items/locations";
    }
}
